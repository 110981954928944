import randomise from 'randomatic';
import { cast, flow, getSnapshot, types, applySnapshot } from 'mobx-state-tree';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  CUSTOMER_BASIC_INFO_SELECTOR,
  CUSTOMER_PROFILE_SELECTOR,
  MYORDERS_SELECTOR,
  ORDER_DETAILS_SELECTOR,
  REORDER_DETAILS_SELECTOR,
  CUSTOMER_ADDRESS_SELECTOR,
  REQUEST_CUSTOMER_EMAIL_UPDATE,
  INITIAL_EMAIL_CHANGE,
  PROFILE_STATE_SELECTOR,
} from '@/graphql/selectors/auth';
import { CustomerModel, CancelReasonsModel } from './customer';
import { DELIVERY_INFO_SELECTOR } from '@/graphql/selectors';
import type { Address } from '@/models/customer/address';
import type { Customer } from '@/models/customer/customer';
import { withRootStore } from '@/models/helpers/with-root-store';
import { AuthRole, OrderModel, OrderStatus } from '../api';
import { DEFAULT_ERROR_MESSAGE, GQL_QUERY_OPTIONS } from '@/utils/constants';
import { API_QUERY_LIMIT } from '@/shared/constant';
import * as Sentry from '@sentry/nextjs';
import {
  CustomerIdentifierAnswersInput,
  CustomerIdentifierInput,
  PrepareCustomerIdentifierCodeInput,
  VerifyCustomerIdentifierCodeInput,
} from '../api/RootStore.base';
import { Attribute } from '@/shared/types';
import {
  trackCheckoutStepCompleted,
  trackIdentify,
} from '@/utils/track/tracker.helper';
import {
  CheckoutCompletionType,
  CheckoutStep,
} from '@/utils/track/tracker.constant';
import cart from '@/pages/cart';
import dayjs from 'dayjs';

const ANON_USER = {
  _id: `ANON-${randomise('0', 13)}`,
  role: AuthRole.ANONYMOUS,
  firstName: '',
  lastName: '',
  email: '',
};

const BLANK_GROWTHBOOK_ATTRIBUTES = {
  mongoId: undefined,
  segmentSuveryCompleted: undefined,
  internalMMCLogin: undefined,
  accountCreatedAt: undefined,
  daysSinceLastOrder: undefined,
  numberofOrders: undefined,
  isSubscriber: undefined,
  deliveryPostcode: undefined,
  deliveryState: undefined,
  deliverySuburb: undefined,
  loggedIn: undefined,
  domain: undefined,
};

export const CustomerStoreModel = types
  .model('CustomerStore')
  .props({
    isLoading: types.optional(types.boolean, true),
    customer: types.optional(
      types.late(() => CustomerModel),
      ANON_USER,
    ),
    orders: types.optional(types.array(OrderModel), []),
    orderDetail: types.maybe(OrderModel),
    cancelReasons: types.optional(CancelReasonsModel, {}),
    error: types.optional(types.string, ''),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isLoggedIn() {
      return self.customer.role === AuthRole.CUSTOMER;
    },
    get isAnon() {
      return self.customer.role === AuthRole.ANONYMOUS;
    },
    isProductLiked(sku: string) {
      return self.customer.productLikes?.includes(sku);
    },
    get promotionalCampaign() {
      return self.customer?.promotionalCampaign;
    },
    get isCustomerProfileLoaded() {
      return self.customer.referCode;
    },
  }))
  .views((self) => ({
    get growthbookCustomerAttributes() {
      if (!self.isLoggedIn) return BLANK_GROWTHBOOK_ATTRIBUTES;
      const domain = self.customer.email?.split('@')?.pop() ?? '';
      return {
        mongoId: self.customer._id,
        segmentSuveryCompleted: self.customer.profileForm === undefined,
        internalMMCLogin: domain.includes('mymusclechef'),
        accountCreatedAt: new Date(self.customer.createdAt).getTime(),
        daysSinceLastOrder: self.customer.daysSinceLastOrder,
        numberofOrders: self.customer.numberOfOrders,
        isSubscriber: self.customer.isSubscriber,
        deliveryPostcode: self.customer.defaultAddress?.postcode,
        deliveryState: self.customer.defaultAddress?.state,
        deliverySuburb: self.customer.defaultAddress?.suburb,
        loggedIn: self.isLoggedIn,
        domain,
      };
    },
  }))
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      setCustomer(customer: Customer) {
        self.customer = CustomerModel.create(customer);
        if (customer._id) {
          Sentry.setUser({ id: customer._id, role: customer.role });
        }
      },
      setCustomerProfile(customer: any) {
        self.customer = cast({ ...getSnapshot(self.customer), ...customer });
      },
      updateProductLike(sku: string, liked: boolean) {
        if (liked) {
          self.customer.productLikes = [
            ...(self.customer.productLikes ?? []),
            sku,
          ];
        } else {
          self.customer.productLikes = self.customer.productLikes?.filter(
            (product) => product !== sku,
          );
        }
      },
      setOrdersList(ordersList: any) {
        self.orders = cast(ordersList);
      },
      setOrderDetail(orderDetail: any) {
        self.orderDetail = cast(orderDetail);
      },
      setCancelReasons(cancelReasons: any) {
        self.cancelReasons = CancelReasonsModel.create(cancelReasons);
      },
      setIsLoading(isLoading: boolean) {
        self.isLoading = isLoading;
      },
    };
  })
  .actions((self) => ({
    getCustomerProfile: flow(function* () {
      try {
        const { customer } = yield self.rootStore.api.queryCustomer(
          {},
          CUSTOMER_PROFILE_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        self.setCustomerProfile(customer);
        self.isLoading = false;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    getProfileStage: flow(function* () {
      try {
        if (!self.isLoggedIn) return;
        const profileStageCompletedAt = self.customer.profileStageCompletedAt;
        const profileStageSkipUntil = parseInt(
          process.env.NEXT_PUBLIC_PROFILE_STAGE_SKIP_UNTIL ?? '7',
          10,
        );
        const isProfileStageNotSkipped =
          profileStageCompletedAt &&
          dayjs().diff(dayjs(profileStageCompletedAt), 'days') <=
            profileStageSkipUntil;
        if (isProfileStageNotSkipped) {
          return;
        }
        const profileStages = self.customer.profileStages ?? [];
        const variables = {
          filter: {
            name: { notIn: profileStages },
            active: { eq: true },
          },
          orderBy: 'priority_ASC',
        };
        const { profileStageCms } =
          yield self.rootStore.api.queryProfileStageCms(
            variables,
            PROFILE_STATE_SELECTOR,
          ).promise;
        self.setCustomerProfile({
          ...self.customer,
          profileStageCms,
        });
        self.customer.updateIsProfileStageNotSkipped();
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    getProfileStageByName: flow(function* (name: string) {
      try {
        const variables = {
          filter: { name: { eq: name }, active: { eq: true } },
        };
        const { profileStageCms } =
          yield self.rootStore.api.queryProfileStageCms(
            variables,
            PROFILE_STATE_SELECTOR,
          ).promise;
        return profileStageCms;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    getCustomerInfo: flow(function* () {
      try {
        const { customerInfo } = yield self.rootStore.api.queryCustomerInfo(
          {},
          CUSTOMER_BASIC_INFO_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        self.setCustomer(customerInfo);
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    checkCustomerInfo: flow(function* () {
      try {
        const { customerInfo } = yield self.rootStore.api.queryCustomerInfo(
          {},
          CUSTOMER_BASIC_INFO_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        return customerInfo?.role === AuthRole.CUSTOMER;
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    }),
    validateAddress: flow(function* (
      lookup: string,
      suburb: string,
      state: string,
    ) {
      try {
        const { deliveryInfo } = yield self.rootStore.api.queryDeliveryInfo(
          { lookup, suburb },
          DELIVERY_INFO_SELECTOR,
        ).promise;
        return (
          deliveryInfo &&
          deliveryInfo.length > 0 &&
          deliveryInfo[0].state === state
        );
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    }),
    getDeliveryInfo: flow(function* (lookup: string, suburb: string) {
      try {
        const { deliveryInfo } = yield self.rootStore.api.queryDeliveryInfo(
          { lookup, suburb },
          DELIVERY_INFO_SELECTOR,
        ).promise;
        return deliveryInfo;
      } catch (error) {
        Sentry.captureException(error);
        return false;
      }
    }),
    createAddress: flow(function* (address: Address) {
      try {
        let firstAddress;
        if (_.isEmpty(self.customer.addresses)) {
          firstAddress = true;
        }
        self.isLoading = true;
        const { createAddress: customerAddress } =
          yield self.rootStore.api.mutateCreateAddress(
            { address },
            CUSTOMER_ADDRESS_SELECTOR,
          ).promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        if (firstAddress) {
          trackCheckoutStepCompleted(
            self.rootStore.cartStore.cart,
            CheckoutStep.DeliveryAddress,
            CheckoutCompletionType.FirstFill,
          );
          trackCheckoutStepCompleted(
            self.rootStore.cartStore.cart,
            CheckoutStep.DeliveryDateTime,
            CheckoutCompletionType.FirstFill,
          );
          trackCheckoutStepCompleted(
            self.rootStore.cartStore.cart,
            CheckoutStep.DeliveryFrequency,
            CheckoutCompletionType.FirstFill,
          );
        } else {
          trackCheckoutStepCompleted(
            self.rootStore.cartStore.cart,
            CheckoutStep.DeliveryAddress,
            CheckoutCompletionType.Update,
          );
        }
        // display message
        const { message } = customerAddress;
        if (message?.message) {
          return message;
        }
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        Sentry.captureException(error);
        self.isLoading = false;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    updateAddress: flow(function* (address: Address) {
      try {
        self.isLoading = true;
        yield self.rootStore.api.mutateUpdateAddress({
          address,
          addressName: address.addressId,
        }).promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        trackCheckoutStepCompleted(
          cart,
          CheckoutStep.DeliveryAddress,
          CheckoutCompletionType.Update,
        );
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        Sentry.captureException(error);
        self.isLoading = false;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    createPayment: flow(function* (payment: any) {
      try {
        self.isLoading = true;
        const { createPaymentMethod } =
          yield self.rootStore.api.mutateCreatePaymentMethod({ input: payment })
            .promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        return createPaymentMethod;
      } catch (error: any) {
        self.isLoading = false;
        Sentry.captureException(error);
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    setDefaultPayment: flow(function* (paymentId: string) {
      try {
        self.isLoading = true;
        yield self.rootStore.api.mutateSetDefaultPaymentMethod({
          paymentId,
        }).promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        return true;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        self.isLoading = false;
        const errorMessage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMessage);
        return false;
      }
    }),
    getMyOrdersList: flow(function* (
      offset: number = 0,
      limit: number = API_QUERY_LIMIT,
    ) {
      try {
        const { orders } = yield self.rootStore.api.queryOrders(
          {
            limit,
            offset,
            sort: [
              {
                fieldName: 'deliveryDate',
                sortDirection: 'DESC',
              },
            ],
            where: {
              orderStatus: {
                _in: [OrderStatus.PROCESSED],
              },
            },
          },
          MYORDERS_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        offset
          ? self.setOrdersList(_.concat(getSnapshot(self.orders), orders))
          : self.setOrdersList(orders);
        return orders.length;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        self.isLoading = false;
        toast.error(errors[0].message || DEFAULT_ERROR_MESSAGE);
      }
    }),
    orderDetails: flow(function* (invoice: string, email: string) {
      self.error = '';
      try {
        const { order } = yield self.rootStore.api.queryOrder(
          {
            lookup: invoice,
            email,
          },
          ORDER_DETAILS_SELECTOR,
        ).promise;
        self.setOrderDetail(order);
        return order;
      } catch (error: any) {
        Sentry.captureException(error);
        self.isLoading = false;
        throw error;
      }
    }),
    reorderDetails: flow(function* (invoice: string) {
      try {
        const { reorder } = yield self.rootStore.api.queryReorder(
          {
            lookup: invoice,
          },
          REORDER_DETAILS_SELECTOR,
        ).promise;
        const { message } = reorder;
        if (message?.message) {
          self.rootStore.generalStore.createNotification(message);
        }
        return reorder;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        self.isLoading = false;
        toast.error(errors[0].message || DEFAULT_ERROR_MESSAGE);
      }
    }),
    cancelSubscription: flow(function* (reason: string) {
      try {
        yield self.rootStore.api.mutateCancelSubscription({
          input: {
            reason: reason,
          },
        }).promise;
        return 'CANCELLED';
      } catch (error) {
        Sentry.captureException(error);
        return 'error';
      }
    }),
    updateCustomerProfileStageAttributes: flow(function* (
      attributes: Attribute[],
    ) {
      try {
        yield self.rootStore.api.mutateUpdateCustomerAttributes(
          {
            input: {
              attributes,
            },
          },
          CUSTOMER_PROFILE_SELECTOR,
        ).promise;
        yield self.getCustomerProfile();
        yield self.getProfileStage();
      } catch (error) {
        Sentry.captureException(error);
        toast.error('An error occurred while updating your profile');
        throw error;
      }
    }),
    updateCustomerUnsubscribeEmail: flow(function* (
      unsubscribeEmailStatus: boolean,
    ) {
      self.isLoading = true;
      try {
        yield self.rootStore.api.mutateUpdateCustomerAttributes(
          {
            input: {
              attributes: [
                { name: 'unsubscribeEmail', value: unsubscribeEmailStatus },
              ],
            },
          },
          CUSTOMER_PROFILE_SELECTOR,
        ).promise;
        self.setCustomerProfile({
          ...self.customer,
          unsubscribeEmailStatus,
        });
        toast.success('Email preferences updated successfully');
      } catch (error) {
        Sentry.captureException(error);
        toast.error('An error occurred while updating email preferences');
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    updateCustomerCampaignInfo: flow(function* (campaignInfo: any) {
      try {
        yield self.rootStore.api.mutateUpdateCustomerAttributes({
          input: {
            attributes: [{ name: 'campaignInfo', value: campaignInfo }],
          },
        }).promise;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    deleteAddress: flow(function* (addressName: string) {
      try {
        self.isLoading = true;
        yield self.rootStore.api.mutateDeleteAddress({
          addressName: addressName,
        }).promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        return true;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        self.isLoading = false;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    deletePayment: flow(function* (paymentId: string) {
      try {
        self.isLoading = true;
        yield self.rootStore.api.mutateDeletePaymentMethod({
          paymentId: paymentId,
        }).promise;
        yield self.getCustomerProfile();
        self.isLoading = false;
        return true;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        self.isLoading = false;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    loadCustomer: flow(function* () {
      try {
        self.isLoading = true;
        yield self.getCustomerInfo();
        if (self.isLoggedIn) {
          trackIdentify(self.customer._id || '', self.customer);
        }
        self.isLoading = false;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    logout: flow(function* () {
      try {
        self.rootStore.resetCustomer();
        self.isLoading = false;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
  }))
  .actions((self) => ({
    requestCustomerEmailUpdate: flow(function* (newEmail: string) {
      try {
        const variables = { input: { newEmail } };
        yield self.rootStore.api.queryRequestCustomerEmailUpdate(
          variables,
          REQUEST_CUSTOMER_EMAIL_UPDATE,
          GQL_QUERY_OPTIONS,
        ).promise;
        return true;
      } catch (error: any) {
        Sentry.captureException(error);
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    initialCustomerEmailChange: flow(function* (
      input: CustomerIdentifierInput,
    ) {
      try {
        const variables = { input };
        const { initiateEmailChange } =
          yield self.rootStore.api.queryInitiateEmailChange(
            variables,
            INITIAL_EMAIL_CHANGE,
            GQL_QUERY_OPTIONS,
          ).promise;
        return initiateEmailChange;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    validateIdentifierQuestions: flow(function* (
      input: CustomerIdentifierAnswersInput,
    ) {
      try {
        const variables = { input };
        const { validateIdentifierQuestions } =
          yield self.rootStore.api.queryValidateIdentifierQuestions(
            variables,
            INITIAL_EMAIL_CHANGE,
            GQL_QUERY_OPTIONS,
          ).promise;
        return validateIdentifierQuestions;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    generateIdentifierCode: flow(function* (
      input: PrepareCustomerIdentifierCodeInput,
    ) {
      try {
        const variables = { input };
        const { generateIdentifierCode } =
          yield self.rootStore.api.queryGenerateIdentifierCode(
            variables,
            REQUEST_CUSTOMER_EMAIL_UPDATE,
            GQL_QUERY_OPTIONS,
          ).promise;
        return generateIdentifierCode;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    confirmIdentifierCode: flow(function* (
      input: VerifyCustomerIdentifierCodeInput,
    ) {
      try {
        const variables = { input };
        const { confirmIdentifierCode } =
          yield self.rootStore.api.mutateConfirmIdentifierCode(
            variables,
            REQUEST_CUSTOMER_EMAIL_UPDATE,
          ).promise;
        return confirmIdentifierCode;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
  }));
